import { computed, inject, Injectable } from "@angular/core";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { PartConsumedData } from "src/app/dashboards/components/customDashboardsPage/custom-dashboard/widget/widgetContent.types";
import type { PartDetail } from "src/app/dashboards/components/dashboard-tile-legacy/services/dashboard-tile-modal-legacy.service";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import { ManageBilling } from "src/app/purchasing/services/manageBilling";
import { betterCurrency } from "src/app/shared/pipes/betterCurrency.pipe";
import type { NotUndefined } from "src/app/shared/types/utility-types";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { ExtraTime } from "src/app/tasks/types/extra-time/extra-time.types";
import type { Task } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";

@Injectable({ providedIn: "root" })
export class DashboardModalHelpersService {
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageTask = inject(ManageTask);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageBilling = inject(ManageBilling);
   private readonly manageLang = inject(ManageLang);
   private readonly manageUser = inject(ManageUser);
   private readonly manageParts = inject(ManageParts);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public prepUserTimeSpentModalData(
      taskIDs: Array<number>,
      extraTimeIDs: Array<number>,
   ) {
      const columns = [
         {
            key: "userDisplayName",
            displayName: this.lang().Name,
            sortBy: "userDisplayName",
            manualWidth: 2,
         },
         {
            key: "timeSpent",
            displayName: this.lang().TimeSpent,
            sortBy: "timeSpent",
            manualWidth: 2,
         },
         {
            key: "checklistName",
            displayName: this.lang().TaskName,
            sortBy: "checklistName",
            manualWidth: 2,
         },
         {
            key: "locationName",
            displayName: this.lang().Location,
            sortBy: "locationName",
            manualWidth: 2,
         },
         {
            key: "date",
            displayName: this.lang().Date,
            sortBy: "date",
            manualWidth: 2,
         },
         {
            key: "completionNotes",
            displayName: this.lang().Note,
            sortBy: "completionNotes",
            manualWidth: 2,
         },
      ];

      const options = {
         sortBind: "-loggedDate",
         isModal: true,
      };

      const combinedObjs = this.generateObjsForExtraTimeAndTasks(taskIDs, extraTimeIDs);
      return {
         columns,
         data: combinedObjs,
         options,
      };
   }
   public prepPartsModalData(view: string) {
      let modalColumns;
      if (view === "CostOfPartsConsumed") {
         modalColumns = [
            {
               key: "partName",
               displayName: this.lang().PartName,
               sortBy: "partName",
               manualWidth: 3,
            },
            {
               key: "locationName",
               displayName: this.lang().Location,
               sortBy: "locationName",
               manualWidth: 3,
            },
            {
               key: "partsUsedInTime",
               displayName: this.lang().TotalConsumed,
               sortBy: "partsUsedInTime",
               manualWidth: 3,
            },
            {
               key: "partsUsedInTimeCost",
               displayName: this.lang().TotalCost,
               sortBy: "partsUsedInTimeCost",
               manualWidth: 3,
            },
         ];
      } else {
         modalColumns = [
            {
               key: "partName",
               displayName: this.lang().PartName,
               sortBy: "partName",
               manualWidth: 3,
            },
            {
               key: "locationName",
               displayName: this.lang().Location,
               sortBy: "locationName",
               manualWidth: 3,
            },
            {
               key: "partsUsedInTime",
               displayName: this.lang().TotalConsumed,
               sortBy: "partsUsedInTime",
               manualWidth: 3,
            },
         ];
      }

      const modalOptions = {
         sortBind: "-partName",
         isModal: true,
      };

      return {
         modalColumns,
         modalOptions,
      };
   }

   public prepPOsModalData(view: string) {
      let modalColumns;

      if (view === "numOfPOItems") {
         modalColumns = [
            {
               key: "itemName",
               displayName: this.lang().Name,
               sortBy: "itemName",
               manualWidth: 3,
            },
            {
               key: "poNumberForDisplay",
               displayName: this.lang().PONumber,
               sortBy: "poNumberForDisplay",
               manualWidth: 3,
            },
            {
               key: "qty",
               displayName: this.lang().Qty,
               sortBy: "qty",
               manualWidth: 3,
            },
            {
               key: "total",
               displayName: this.lang().Total,
               sortBy: "total",
               manualWidth: 3,
            },
         ];
      } else if (view === "POSpendEfficiencySavings") {
         modalColumns = [
            {
               key: "poNumberForDisplay",
               displayName: this.lang().PONumber,
               sortBy: "poNumberForDisplay",
               manualWidth: 3,
            },
            {
               key: "date",
               displayName: this.lang().Date,
               sortBy: "date",
               manualWidth: 2,
            },
            {
               key: "total",
               displayName: this.lang().Total,
               sortBy: "total",
               manualWidth: 2,
            },
            {
               key: "efficiencyRate",
               displayName: this.lang().EfficiencyRate,
               sortBy: "efficiencyRate",
               manualWidth: 2,
            },
            {
               key: "efficiencySavings",
               displayName: this.lang().EfficiencySavings,
               sortBy: "efficiencySavings",
               manualWidth: 3,
            },
         ];
      } else {
         modalColumns = [
            {
               key: "poNumberForDisplay",
               displayName: this.lang().PONumber,
               sortBy: "poNumberForDisplay",
               manualWidth: 4,
            },
            {
               key: "currentStatus",
               displayName: this.lang().Status,
               sortBy: "currentStatus",
               manualWidth: 4,
            },
            {
               key: "total",
               displayName: this.lang().Total,
               sortBy: "total",
               manualWidth: 4,
            },
         ];
      }

      const modalOptions = {
         sortBind: "poNumber",
         isModal: true,
      };

      return {
         modalColumns,
         modalOptions,
      };
   }

   public generateObjsForExtraTimeAndTasks(taskIDs, extraTimeIDs) {
      const locationsIndex = this.manageLocation.getLocationsIndex();
      const categoryIndex = this.manageBilling.getCategoriesIndex();

      const taskObjs = taskIDs
         .map((taskID) => this.manageTask.getTaskLocalLookup(taskID))
         .filter((task): task is NotUndefined<typeof task> => task !== undefined)
         .map((task) => {
            const obj: { [key: string]: string | number | null | undefined } = {
               userDisplayName: this.getUserDisplayName(task),
               locationName: task
                  ? (locationsIndex[task.locationID]?.locationName ?? "")
                  : "",
               timeSpent: task.checklistPromptTime ?? 0,
               laborWage: task.checklistUserWage ?? 0,
               date: task.checklistCompletedDate,
               checklistName: task.checklistName,
               assetNameStr: task.assetID
                  ? this.manageAsset.getAsset(task.assetID)?.assetName
                  : "",
               checklistID: task.checklistID,
               completionNotes: task.checklistComments,
            };
            if (task.categoryID !== null && categoryIndex[task.categoryID]) {
               obj.categoryName = categoryIndex[task?.categoryID].categoryName;
               obj.billableTime = (Number(task?.billableTime) / 60 / 60).toFixed(2);
               obj.billableRate = Number(task?.billableRate);
            }
            return obj;
         });
      const extraTimeObjs = extraTimeIDs
         .map((extraTimeID) => this.manageTask.getExtraTime(extraTimeID))
         .filter(
            (extraTime): extraTime is NotUndefined<typeof extraTime> =>
               extraTime !== undefined,
         )
         .map((extraTime) => {
            const task = this.manageTask.getTaskLocalLookup(extraTime.checklistID);
            const obj: { [key: string]: string | number | null | undefined } = {
               userDisplayName: this.getUserDisplayName(extraTime),
               locationName: task
                  ? (locationsIndex[task.locationID]?.locationName ?? "")
                  : "",
               timeSpent: extraTime.promptTime ?? 0,
               laborWage: extraTime.userWage ?? 0,
               date: extraTime.loggedAt,
               checklistName: task?.checklistName,
               assetNameStr: task?.assetID
                  ? this.manageAsset.getAsset(task.assetID)?.assetName
                  : "",
               checklistID: task?.checklistID,
               completionNotes: extraTime.notes,
            };
            if (
               extraTime?.categoryID !== null &&
               extraTime?.categoryID &&
               categoryIndex[extraTime?.categoryID]
            ) {
               obj.categoryName = categoryIndex[extraTime?.categoryID]?.categoryName;
               obj.billableTime = (Number(extraTime?.billableTime) / 60 / 60).toFixed(2);
               obj.billableRate = Number(extraTime?.billableRate);
            }
            return obj;
         });

      return [...taskObjs, ...extraTimeObjs];
   }

   public generateObjsForPartConsumedModal(partsData: PartConsumedData): PartDetail[] {
      return (partsData.partIDs ?? []).reduce<PartDetail[]>((acc, partID) => {
         const part = this.manageParts.getPart(partID);
         if (part === undefined) {
            return acc;
         }

         const totalConsumedWithinTime = partsData.partUsedInTime?.[partID] ?? 0;
         const costOfTotalConsumedWithinTime =
            partsData.partUsedInTimeCost?.[partID] ?? 0;

         let formattedCostOfTotalConsumedWithinTime = betterCurrency(
            0,
            this.manageLang,
            this.manageUser,
         );

         if (totalConsumedWithinTime && costOfTotalConsumedWithinTime) {
            formattedCostOfTotalConsumedWithinTime = betterCurrency(
               costOfTotalConsumedWithinTime,
               this.manageLang,
               this.manageUser,
            );
         }

         const calculatedPartInfo = this.manageParts.getSingleCalculatedPartInfo(
            part.partID,
         );
         const formattedPartPrice = betterCurrency(
            calculatedPartInfo?.averagePrice ?? 0,
            this.manageLang,
            this.manageUser,
         );

         const totalPrice = betterCurrency(
            calculatedPartInfo?.totalPrice ?? 0,
            this.manageLang,
            this.manageUser,
         );

         acc.push({
            ...part,
            totalConsumedWithinTime,
            formattedCostOfTotalConsumedWithinTime,
            formattedPartPrice,
            totalQty: calculatedPartInfo?.totalQty ?? 0,
            totalPrice,
         });

         return acc;
      }, []);
   }

   private getUserDisplayName(obj: Task | ExtraTime | undefined): string {
      if (!obj) {
         return this.lang().DeletedUser;
      }

      let userForDisplay;

      if ("checklistUserCompleted" in obj) {
         userForDisplay = obj.checklistUserCompleted
            ? this.manageTask.getAllUsers().get(obj.checklistUserCompleted)
            : null;
      } else if ("userID" in obj) {
         userForDisplay = obj.userID
            ? this.manageTask.getAllUsers().get(obj.userID)
            : null;
      }

      return userForDisplay
         ? `${userForDisplay.userFirstName} ${userForDisplay.userLastName}`
         : this.lang().DeletedUser;
   }
}
