import { inject, Injectable } from "@angular/core";
import type { ChartDataset } from "chart.js";
import type {
   LineBarGraphContentBase,
   LineBarGraphMyData,
   LineBarGraphMyDataset,
} from "src/app/dashboards/components/customDashboardsPage/custom-dashboard/widget/widgetContent.types";
import { LineBarGraphDisplayHandlerUtils } from "src/app/dashboards/services/line-bar-graph-display-handler/line-bar-graph-display-handler.utils";
import { ManageDashboard } from "src/app/dashboards/services/manageDashboard";
import { WidgetDefService } from "src/app/dashboards/services/widget-def/widget-def.service";
import type {
   WidgetDefinition,
   WidgetModalType,
} from "src/app/dashboards/types/customDashboard.types";
import { assert } from "src/app/shared/utils/assert.utils";

type ChartConversionCallback = (
   widgetContent: LineBarGraphContentBase,
) => LineBarGraphMyData;

export interface LineBarGraphDisplayHandler {
   convertToChartJSStructure: ChartConversionCallback;
   hint: () => string;
   hasModalView?: () => boolean;
   isCurrency?: () => boolean;
   modalType?: () => WidgetModalType | undefined;
}

type LineBarGraphDisplayHandlerParams = {
   convertToChartJSStructure?: ChartConversionCallback;
   source: string;
   hint: string;
   hasModalView?: boolean;
   isCurrency?: boolean;
   widgetViewedAs: string;
   modalType?: WidgetModalType;
   splitBy: string | null;
   widgetType: string | false;
   widgetDisplayView: string;
};

@Injectable({ providedIn: "root" })
export class LineBarGraphDisplayHandlerService {
   private readonly lineBarGraphColors = inject(ManageDashboard).getGraphColors();
   private readonly handlerStrategies = {
      tasks: (widgetDef: WidgetDefinition, params: LineBarGraphDisplayHandlerParams) =>
         this.tasksDisplayHandler(widgetDef, params),
      assets: (widgetDef: WidgetDefinition, params: LineBarGraphDisplayHandlerParams) =>
         this.assetsDisplayHandler(widgetDef, params),
      users: (widgetDef: WidgetDefinition, params: LineBarGraphDisplayHandlerParams) =>
         this.usersDisplayHandler(widgetDef, params),
      parts: (widgetDef: WidgetDefinition, params: LineBarGraphDisplayHandlerParams) =>
         this.partsDisplayHandler(widgetDef, params),
      POs: (widgetDef: WidgetDefinition, params: LineBarGraphDisplayHandlerParams) =>
         this.poDisplayHandler(widgetDef, params),
   };
   private readonly widgetDefService = inject(WidgetDefService);
   private readonly utils = inject(LineBarGraphDisplayHandlerUtils);

   public getHandler(widgetDef: WidgetDefinition): LineBarGraphDisplayHandler {
      const dateRange = this.widgetDefService.formatDateRange(widgetDef);
      const defaultParams: LineBarGraphDisplayHandlerParams = {
         widgetType: widgetDef.type,
         widgetViewedAs: widgetDef.viewedAs,
         source: widgetDef.type === false ? "" : widgetDef.type,
         hint: dateRange,
         splitBy: null, // Initially there is no splitBy decision
         widgetDisplayView: "",
      };
      return this.resolveDisplayHandler(widgetDef, defaultParams);
   }

   private resolveDisplayHandler(
      widgetDef: WidgetDefinition,
      params: LineBarGraphDisplayHandlerParams,
   ): LineBarGraphDisplayHandler {
      if (widgetDef.type === false || !this.handlerStrategies[widgetDef.type]) {
         return this.defaultDisplayHandler(params);
      }
      const handler = this.handlerStrategies[widgetDef.type];
      return handler(widgetDef, params);
   }

   private tasksDisplayHandler(
      widgetDef: WidgetDefinition,
      params: LineBarGraphDisplayHandlerParams,
   ): LineBarGraphDisplayHandler {
      assert(widgetDef.type === "tasks");
      assert(widgetDef.viewedAs === "lineGraph" || widgetDef.viewedAs === "barGraph");
      assert(widgetDef.display.view);

      const dateRange = params.hint;
      params.convertToChartJSStructure = this.getCommonChartConversion(params);
      params.hint = `${this.utils.getHint(
         String(widgetDef.type),
         String(widgetDef.display.view),
      )} - ${dateRange}`;
      params.widgetDisplayView = widgetDef.display.view;
      params.isCurrency = [
         "operatingCost",
         "partsCost",
         "laborCost",
         "invoiceCost",
      ].includes(widgetDef.display.view);

      return this.defaultDisplayHandler(params);
   }

   private assetsDisplayHandler(
      widgetDef: WidgetDefinition,
      params: LineBarGraphDisplayHandlerParams,
   ): LineBarGraphDisplayHandler {
      assert(widgetDef.type === "assets");
      assert(widgetDef.viewedAs === "lineGraph" || widgetDef.viewedAs === "barGraph");
      assert(widgetDef.display.view);

      const dateRange = params.hint;
      let hintBody = "";
      params.convertToChartJSStructure = this.getCommonChartConversion(params);
      params.hasModalView = widgetDef.display.view !== "assetFieldHistory";
      if (widgetDef.display.view === "assetFieldHistory") {
         hintBody = widgetDef.displayData.assetFieldHistory.fieldName;
      } else {
         hintBody = this.utils.getHint(
            String(widgetDef.type),
            String(widgetDef.display.view),
         );
      }
      params.hint = `${hintBody} - ${dateRange}`;
      params.widgetDisplayView = widgetDef.display.view;
      params.isCurrency = [
         "costParts",
         "costTotal",
         "costInvoices",
         "costLabor",
      ].includes(widgetDef.display.view);

      return this.defaultDisplayHandler(params);
   }

   private usersDisplayHandler(
      widgetDef: WidgetDefinition,
      params: LineBarGraphDisplayHandlerParams,
   ): LineBarGraphDisplayHandler {
      assert(widgetDef.type === "users");
      assert(widgetDef.viewedAs === "lineGraph" || widgetDef.viewedAs === "barGraph");
      assert(widgetDef.display.view);

      const dateRange = params.hint;
      params.convertToChartJSStructure = this.getCommonChartConversion(params);
      params.hint = `${this.utils.getHint(
         String(widgetDef.type),
         String(widgetDef.display.view),
      )} - ${dateRange}`;
      params.widgetDisplayView = widgetDef.display.view;

      return this.defaultDisplayHandler(params);
   }
   private poDisplayHandler(
      widgetDef: WidgetDefinition,
      params: LineBarGraphDisplayHandlerParams,
   ): LineBarGraphDisplayHandler {
      assert(widgetDef.type === "POs");
      assert(widgetDef.viewedAs === "lineGraph" || widgetDef.viewedAs === "barGraph");
      assert(widgetDef.display.view);

      const dateRange = params.hint;
      params.hint = `${this.utils.getHint(
         String(widgetDef.type),
         String(widgetDef.display.view),
      )} - ${dateRange}`;
      params.widgetDisplayView = widgetDef.display.view;
      params.isCurrency = ["costOfPOs"].includes(widgetDef.display.view);

      return {
         convertToChartJSStructure: (widgetContent) =>
            widgetContent.labelSplitBy && widgetContent.labelSplitBy.length > 0
               ? (this.convertToChartStructureSplitBy(
                    widgetContent,
                    params,
                 ) as unknown as LineBarGraphMyData)
               : this.convertToChartStructure(widgetContent, params),
         hint: () => params.hint,
         hasModalView: () => params?.hasModalView ?? true,
         isCurrency: () => params?.isCurrency ?? false,
         modalType: () =>
            this.utils.getModalType(params.widgetType, params.widgetDisplayView),
      };
   }
   private partsDisplayHandler(
      widgetDef: WidgetDefinition,
      params: LineBarGraphDisplayHandlerParams,
   ): LineBarGraphDisplayHandler {
      assert(widgetDef.type === "parts");
      assert(widgetDef.viewedAs === "lineGraph" || widgetDef.viewedAs === "barGraph");
      assert(widgetDef.display.view);

      const dateRange = params.hint;
      params.convertToChartJSStructure = this.getCommonChartConversion(params);
      params.hint = `${this.utils.getHint(
         String(widgetDef.type),
         String(widgetDef.display.view),
      )} - ${dateRange}`;
      params.widgetDisplayView = widgetDef.display.view;
      params.isCurrency = ["CostOfPartsConsumed"].includes(widgetDef.display.view);

      return this.defaultDisplayHandler(params);
   }

   private getCommonChartConversion(
      params: LineBarGraphDisplayHandlerParams,
   ): ChartConversionCallback {
      return (widgetContent) =>
         widgetContent.labelSplitBy && widgetContent.labelSplitBy.length > 0
            ? (this.convertToChartStructureSplitBy(
                 widgetContent,
                 params,
              ) as unknown as LineBarGraphMyData)
            : this.convertToChartStructure(widgetContent, params);
   }

   private defaultDisplayHandler(
      params: LineBarGraphDisplayHandlerParams,
   ): LineBarGraphDisplayHandler {
      return {
         convertToChartJSStructure:
            params?.convertToChartJSStructure ??
            ((widgetContent) => this.convertToChartStructure(widgetContent, params)),
         hint: () => params.hint,
         hasModalView: () => params?.hasModalView ?? true,
         isCurrency: () => params?.isCurrency ?? false,
         modalType: () =>
            this.utils.getModalType(params.widgetType, params.widgetDisplayView),
      };
   }

   private convertToChartStructure(
      widgetContent: LineBarGraphContentBase,
      params,
   ): LineBarGraphMyData {
      return this.createDatasetStructure(widgetContent, params, false);
   }

   private convertToChartStructureSplitBy(
      widgetContent: LineBarGraphContentBase,
      params,
   ): LineBarGraphMyData {
      return this.createDatasetStructure(widgetContent, params, true);
   }

   private createDatasetStructure(
      widgetContent: LineBarGraphContentBase,
      params,
      isSplitBy: boolean,
   ): LineBarGraphMyData {
      const colors = this.utils.getWidgetTypeColorStyles(params);
      const datasets = isSplitBy
         ? widgetContent.labelSplitBy
              .map((label, index) => {
                 return this.createDataset(
                    widgetContent.datasets[index],
                    label,
                    params,
                    colors[index % colors.length],
                 );
              })
              .filter((dataset) => dataset !== undefined)
         : [this.createDataset(widgetContent.datasets[0], "", params, colors[0])];
      return {
         labels: widgetContent.labels,
         datasets: datasets,
      };
   }

   private createDataset(
      datasetsForCategory,
      label: string,
      params,
      color,
   ): LineBarGraphMyDataset {
      const labelFinal = label
         ? this.utils.getLabel(params.widgetType, params.widgetDisplayView, label)
         : this.utils.getLabel(params.widgetType, params.widgetDisplayView);
      const title = this.utils.getTitle(
         params.widgetType,
         params.widgetDisplayView,
         labelFinal,
      );
      const returnDataset: ChartDataset<"line" | "bar"> = {
         tension: 0.5,
         pointRadius: 3,
         fill: false,
         xAxisID: "xAxes",
         yAxisID: "yAxes",
         data: (datasetsForCategory ?? []).map((dataset) => {
            return this.utils.roundNumber(dataset.value);
         }),
         label: labelFinal,
         backgroundColor: "",
         borderColor: "",
         pointBackgroundColor: "",
         pointHoverRadius: 6,
         pointHoverBackgroundColor: color.pointHighlightFill || color.pointColor,
      };
      const dataSrc = this.buildDataSrc(datasetsForCategory ?? []);
      if (label) {
         this.utils.updateGraphColors(
            returnDataset,
            color.pointColor,
            params.widgetViewedAs,
         );
      } else {
         this.utils.updateGraphColors(
            returnDataset,
            this.lineBarGraphColors[0].pointColor,
            params.widgetViewedAs,
         );
      }
      return {
         dataset: returnDataset,
         dataSrc: dataSrc,
         title: title,
      };
   }

   private buildDataSrc(datasetsForCategory): Array<{ customData: any }> {
      return datasetsForCategory.map((dataset) => {
         if (dataset.customData && "tasks" in dataset.customData) {
            if (dataset.customData && "extraTimes" in dataset.customData) {
               return {
                  customData: {
                     tasks: dataset.customData.tasks,
                     extraTimes: dataset.customData.extraTimes,
                  },
               };
            }
            return { customData: dataset.customData.tasks };
         } else if (dataset.customData && "parts" in dataset.customData) {
            return { customData: dataset.customData.parts };
         } else if (dataset.customData && "poIDs" in dataset.customData) {
            return { customData: dataset.customData.poIDs };
         } else if (dataset.customData && "poItemIDs" in dataset.customData) {
            return { customData: dataset.customData.poItemIDs };
         }
         return { customData: undefined };
      });
   }
}
