import { computed, inject, Injectable } from "@angular/core";
import { ManageDashboard } from "src/app/dashboards/services/manageDashboard";
import type { WidgetDefinition } from "src/app/dashboards/types/customDashboard.types";
import { ManageLang } from "src/app/languages/services/manageLang";

@Injectable({
   providedIn: "root",
})
export class WidgetDefService {
   private readonly manageLang = inject(ManageLang);
   private readonly manageDashboard = inject(ManageDashboard);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public formatDateRange(widgetDef: WidgetDefinition): string {
      if (widgetDef.dateStr === "All Time") {
         return this.lang().AllTime;
      }

      const temp = this.manageDashboard.findMomentStartBasedOnDateStr(widgetDef);
      const start = temp.start;
      const end = temp.end;
      return `${start.format("ll")} - ${end.format("ll")}`;
   }
}
